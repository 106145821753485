import { useState } from "react";
import styled from "styled-components";

import { getJwtForUserFromOneTimeCode } from "api/services/authenticationService";
import Button from "components/ui/Button";
import { useSearchParams } from "react-router-dom";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import MultiFieldInput from "components/ui/MultiFieldInput";

const ModalContent = styled.div`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 10px;
  width: 450px;
`;

const Logo = styled.img`
  margin: 0;
  width: 50px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.color.error};
  width: max-content;
  text-align: center;
  height: 40px;
  width: 300px;
`;

const Form = styled.form`
  width: 400;
  padding: 35px 35px 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: grid;
  justify-items: center;
  padding-top: 15px;
`;

const Container = styled.div`
  padding-top: 200px;
  display: grid;
  justify-content: center;
`;

const InfoMsg = styled.div`
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  padding-top: 36px;
  padding-bottom: 20px;
`;

const OneTimeCodePage = () => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();

  const logInAndRedirect = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await getJwtForUserFromOneTimeCode(searchParams?.get("userId"), code);
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    setLoginTokenInClientStorage(data.accessToken);
    window.location = "/";
  };

  return (
    <Container>
      <ModalContent>
        <Form onSubmit={logInAndRedirect}>
          <Logo src={`/images/logo-black.png`} />
          <InfoMsg>Check your email for one time pass code.</InfoMsg>
          <MultiFieldInput onNewValue={newCode => setCode(newCode)} />
          <ButtonContainer>
            <ErrorMessage>{error?.message}</ErrorMessage>
            <Button isDisabled={code?.length !== 6} type="submit" value={isLoading ? "Loading..." : "Login"} />
          </ButtonContainer>
        </Form>
      </ModalContent>
    </Container>
  );
};

export default OneTimeCodePage;
