import styled from "styled-components";

export const BigTitle = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  font-size: 26px;
  font-weight: bold;
  line-height: 1.25;
`;

export const SmallTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
