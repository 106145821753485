import styled from "styled-components";
import { useState } from "react";
import { range } from "lodash";

const InputsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  font-size: 32px;
  width: 40px;
  outline: none;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const MultiFieldInput = ({ numFields = 6, onNewValue }) => {
  const [inputVals, setInputVals] = useState(range(numFields).map(_ => ""));

  return (
    <InputsContainer>
      {range(numFields).map(i => {
        const focusNextInput = i => {
          const nextInput = document.querySelector(`#input-${i + 1}`);
          nextInput?.focus();
        };

        const focusPrevInput = i => {
          const prevInput = document.querySelector(`#input-${i - 1}`);
          prevInput?.focus();
        };

        return (
          <Input
            id={`input-${i}`}
            maxLength={1}
            key={i}
            value={inputVals[i]}
            onChange={e => {
              const newLetter = e.target.value;
              const newInputVals = [...inputVals];
              newInputVals[i] = newLetter;
              setInputVals(newInputVals);
              onNewValue(newInputVals.join(""));

              if (newLetter === "") {
                return;
              }
              focusNextInput(i);
            }}
            onKeyDown={e => {
              if (e.key === "Backspace" && e.target.value === "") {
                focusPrevInput(i);
              }
            }}
          />
        );
      })}
    </InputsContainer>
  );
};

export default MultiFieldInput;
